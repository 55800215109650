// external fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700|Raleway:400,700);


@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.woff2') format('woff2'),
        url('../fonts/icomoon.woff') format('woff'),
        url('../fonts/icomoon.ttf') format('truetype'),
        url('../fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*
@font-face {
    font-family: 'mv_boliregular';
    src: url('../fonts/mvboli/mvboli-webfont.woff2') format('woff2'),
         url('../fonts/mvboli/mvboli-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
*/