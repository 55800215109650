.banner {
    display: block;
    text-align: left;
    .clearfix;

    figure {
        position: relative;
        background-size: cover;
		@media @phone, @xsmall {
        	background-size: contain;
		}
		background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        //height: 110px;

        .make-sm-column(12);
        padding-left: 0;
        padding-right:0;
		.banners-122 & {
			@media @large {
				height: 300px;
			}
			@media @medium {
				height: 240px;
			}
			@media @small {
				height: 180px;
			}
			@media @phone, @xsmall {
				height: 150px;
			}
		}
		.banners-123 & {
			@media @large {
				height: 219px;
			}
			@media @medium {
				height: 240px;
			}
			@media @small {
				height: 180px;
			}
			@media @phone, @xsmall {
				height: 150px;
			}
		}
    }
    span {
        display: block;
        .make-sm-column(12);
        padding-top: 15px;

        h3 {
			font-weight: @titleweight2 !important;
			text-align: center;
			&:after {
			   .icon;
			   content: '\f105';
				margin-left: 8px;
				vertical-align: middle;
			}
        }

        p {
            text-align: center;
            .font-size(16) !important;
            line-height: 1.5 !important;
            color: @textcolor !important;
            font-weight: 300 !important;
            text-decoration: none !important;
            margin: 0 !important;
            @media @small {
                display: none;
            }
        }
    }


    &.active, &:hover {
        text-decoration: none !important;
		figure {
			opacity: 0.6;
		}
        h3, p {
            text-decoration: none !important;
			color: @contrast1 !important;
        }

    }

}

/*.banner-up,
.banner-down {
    display: block;
    border: 4px solid transparent;
    .lh-border-radius(9);
    height: 260px;

    figure {
        width: 90%;
        max-width: 170px;
        height: 170px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-size: contain;
        .lh-transition(transform 0.2s ease);
    }

    span {
        position: absolute;
        left: 50%;
        display: block;
        width: 160px;
        height: 69px;
        margin-left: -80px;


        h3 {
            color: @black;
            text-align: center;
            margin: 0;
            .font-size(20);
            font-weight: 600;
        }
    }

    &:hover {
        figure {
            .lh-transform(scale(1.15));
        }
    }

    &.active {
        text-decoration: none;
        border-color: @white;
        figure {
            .lh-transform(scale(1)) !important;
        }
    }
}

.banner-up {

    span {
        background: url('../images/balloon-down.png') no-repeat;
        padding: 35px 5px 0 5px;
        top: 145px;

    }
}

.banner-down {
    figure {
        margin-top: 70px;
    }

    span {
        background: url('../images/balloon-up.png') no-repeat;
        padding: 5px 5px 0 5px;
        top: 13px;
    }
}*/
