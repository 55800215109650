section.leeg-81 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: transparent !important;

    .block {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media @medium, @large {
            height: @leegheight;
        }
        @media @medium {
            height: (@leegheight*0.8);
        }
        @media @small {
            height: (@leegheight*0.6);
        }
        @media @phone, @xsmall {
            height: (@leegheight*0.4);
        }
    }

    &.bgcolor-1 {
        .block {
            &:extend(.content > section.bgcolor-1);
        }
    }
    &.bgcolor-2 {
        .block {
            &:extend(.content > section.bgcolor-2);
        }
    }
    &.bgcolor-3 {
        .block {
            &:extend(.content > section.bgcolor-3);
        }
    }
    &.bgcolor-4 {
        .block {
            &:extend(.content > section.bgcolor-4);
        }
    }
    &.bgcolor-5 {
        .block {
            &:extend(.content > section.bgcolor-5);
        }
    }
}


section.c_streep {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .block {
		border-top: 1px solid @bordercolor;
    }	
}