* {
    .lh-box-sizing(border-box);
}

html {
    font-size: 62.5%;
}

html,
body {
    margin: 0;
    padding: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
}

body {
    font-family: @contentfont;
    color: @textcolor;
	font-weight: 300;
    .font-size(16);
    line-height: 1.6;

    @media @phone, @xsmall {
        //.font-size(18);
    }
}

a {
    color: @color1;
    text-decoration: none;

    &:link {
        text-decoration: none;
        outline: 0;
    }

    &:visited {
        text-decoration: none;
        outline: 0;
    }

    &:active {
        text-decoration: none;
        outline: 0;
    }

    &:hover {
        text-decoration: underline;
        outline: 0;
    }
    .filelist & {

        &[href $='.pdf'],
        &[href $='.xls'],
        &[href $='.xlsx'],
        &[href $='.doc'],
        &[href $='.docx'],
        &[href $='.zip'],
        &[href $='.ppt'],
        &[href $='.pptx'] {
            &:not(.no-icon){
                &:before {
                    .icon;
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 120%;
                    vertical-align: text-bottom;
                    content: "\e907";
                }
            }
        }
        &[href $='.pdf']:before { content: '\f1c1' !important;}
        &[href $='.xls']:before,
        &[href $='.xlsx']:before { content: '\f1c3' !important;}
        &[href $='.doc']:before,
        &[href $='.docx']:before { content: '\f1c2' !important;}
        &[href $='.ppt']:before,
        &[href $='.pptx']:before { content: '\f1c4' !important;}
        &[href $='.jpg']:before,
        &[href $='.png']:before { content: '\f1c5' !important;}
        &[href $='.zip']:before { content: '\f1c6' !important;}
    }
}

.header {
    background: @white;
    position: relative;
    z-index: 25;


    @media @phone, @xsmall {
    }

    .logo {
        position: relative;
        z-index: 20;
        .make-xs-column(6);
        .make-sm-column(6);
        padding: 0 15px 0 30px;
		margin: 15px 0 15px 0;
        a {
            display: block;
            height: 100%;
        }
        img {
            max-width: 100%;
        }

        @media @small, @medium {
			margin: 15px 0 25px;
        }

        @media @phone, @xsmall {
			margin: 10px 0;
			padding: 0 0 0 15px;
        }
        .clearfix;
    }


}

.header-options {
    .make-xs-column(6);
    .make-sm-column(6);
	padding: 0 30px 0 15px;
    @media @nonmobile {
        span.group-links {
            padding: 5px 15px;
            //background-color: @color1;
            a {
                color: @color1;
                &:after {
                    color: @color1;

                }
            }
        }
    }
	a {
		position: relative;
		display: inline-block;
		&:after {
			.icon;
			content: " ";
			text-align: center;
			display: inline-block;
			//background-color: @color1;
            margin-left: 10px;
			color: @color1;
		}
		&[href^='tel:']:after {
			content: " \f095";
		}
		&[href^='mailto:']:after {
			content: " \f1fa";
		}
		&[href^='https://www.face']:after {
			content: " \f09a";
		}
		&[href^='https://www.link']:after {
			content: " \f0e1";
		}
	}
    a + a {
        &:before {
            content: ' | ';
        }
    }
	
	@media @small, @medium, @large {
		a {
			margin-bottom: 5px;
		}
	}

	@media @xsmall, @phone {
		margin: 15px 0;
        padding: 0;
        .navbar-toggle {
            background-color: @white;
            margin: 0 15px 0 8px;
            margin-right: 0 !important;
            .icon-bar {
                background-color: @color1;
            }
        }
		

		a {
			display: inline-block;
			width: 24px;
			height: 24px;
			text-indent: -9999px;
			text-align: left;
            margin-left: 0;
			&:after {
				margin: 0;
				top: 5px;
				right: 0;
				position: absolute;
				text-indent: 0;
                width: 24px;
                height: 24px;
                line-height: 24px;


			}
		}
		a {
			//margin-left: 8px;
            margin-left: 0;
		}
		br {
			display: none;
		}
	}
	
	@media @small {
	}


	
	br {
		line-height: 32px;
	}


	
    text-align: right;
	
    @media @phone, @xsmall {
    }
}



/*
.searchform {

    input {
        float: left;
        width: 200px;
        padding: 0 10px;
        border: 1px solid #b5baba;
        border-right: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        -webkit-appearance: none;
        .lh-border-radius(0);
    }
    button {
        float: left;
        width: 30px;
        text-align: center !important;
        border: 1px solid #b5baba;
        border-left: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        line-height: 1;
        -webkit-appearance: none;
        padding-top: 5px;

        &:after {
            .icon;
            color: fade(@grey, 40%);
            content: '\e900';
            .font-size(20);
        }
    }
}*/

/*

.searchresult {
    .make-md-column(10);
    padding: 0;
    margin: 0;

    .font-size(18) !important;
    font-weight: normal !important;

    h3 {
        .font-size(20) !important;
        margin: 0 !important;
    }
    p {

    }

    &:hover {
        color: @color1 !important;
        text-decoration: none !important;

        h3 {
            color: @color1 !important;
        }
    }
}
*/

.mainmenu {

    position: relative;
    z-index: 10;

    .container {
        padding: 0;
    }
    @media @large {
        height: 50px;

    }
    @media @medium {
        height: 50px;

    }
    @media @small {
        height: 50px;

    }
	// .lh-background-image(linear-gradient(to bottom, @grijs 0%, @grijs-donker 100%));
    background-color: @grey;
    border-bottom: 5px solid @grey;

    @media @phone, @xsmall {
        .container {
            padding: 0;
            height: 5px;
        }
    }

    .navbar-header {
        background-color: @contrast1;
        margin: 0;

        .menu-header {
            @media @small, @medium, @large {
                display: none;
            }

            float: left;
            margin-left: 15px;
            max-width: 80%;

            a {
                display: inline-block;
                .font-size(16);
                line-height: 1;
                color: @color2;
                font-weight: bold;
                padding: 15px 0;
            }
        }

        .navbar-toggle {
            background-color: @color2;
            margin-right: 15px;

            .icon-bar {
                background-color: @color1;
            }
        }
    }

    .menu {
        position: relative;
        z-index: 2;
        padding: 0;
        .lh-box-shadow(none);

        @media @small, @medium, @large {
        }

        @media @phone, @xsmall {
            padding: 5px 15px;
            background: fade(@color1,90%);
            //margin: 0;
        }
        > ul {
            width: 100%;
            .no-bullets;
            .clearfix;
            text-align: left;
            @media @phone, @xsmall {
                padding-bottom: 15px;
            }

            > li {
                position: relative;
                display: inline-block;
                &.right {
                    float: right;
                }
                @media @phone, @xsmall {
                    padding: 0;
                    display: block;
                    width: 100%;
                }

                a {
                    display: block;
                    color: @white;
                    .font-size(16);
					font-family: @titlefont;
                    font-weight: normal;

                    //.lh-transition(background 0.15s ease);

                    @media @phone, @xsmall {
                        padding: 5px 0;
                        height: auto;
                        text-align: left;
                        .font-size(20);
                    }

                    @media @small, @medium, @large {
                        line-height: 1.1;
                        text-align: center;
                    }

                    @media @small {
                        .font-size(16);
                        padding: 17px 15px;
                        max-height: 57px;
                    }

                    @media @medium {
                        padding: 17px 20px ;
                        max-height: 67px;
                    }

                    @media @large {
                        max-height: 50px;
                        padding: 17px 20px;
                        &.fixed {
                            padding: 0px 5px;
                            line-height: 50px;
                        }
                    }
                    &:after {
                        .icon;
                        text-align: center;
                        display: inline-block;
                        font-size: 25px;
                        //background-color: @color1;
                        margin-left: 10px;
                        color: @white;
                    }
                    &[href^='tel:']:after {
                        //content: " \f095";
                    }
                    &[href^='mailto:']:after {
                        content: "\f1fa";
                    }
                    &[href^='https://www.face']:after {
                        content: "\f09a";
                    }
                    &[href^='https://www.link']:after {
                        content: "\f0e1";
                    }
                }

                &:first-child {

                }

                &:hover {
                    > a {
                        text-decoration: none;
                        background-color: @color1;
                      color: @white;
                    }

                    ul {
                        display: block;
                    }
                }



                @media @small, @medium, @large {
                    display: inline-block;

                    > a {
                        //letter-spacing: 0.25px;
                    }

                    &.active {
                        > a {
                            background-color: @grey;
							color: @white;

                        }
                    }

                }

                ul {
                    .no-bullets;
                    //.lh-box-shadow(2px 2px 4px 0 rgba(0,0,0,0.2));
                    text-align: left;
                    //background-color: fade(@color1, 80%);
                    
                    @media @small, @medium, @large {
                        display: none;

                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 15;
                        //background: @white;
                        min-width: 300px;
                        //border: 1px solid #bbb;
                        border-top: none;
                        //padding: 10px 0 0 0;

                        li {

                            a {
                                display: block;
                                text-align: left;
                                color: @white;
                                background: @color1;
                                //background: fade(@white, 90%);
                                .lh-transition(background 0.2s ease);
                                padding-top: 8px;
                                padding-bottom: 8px;
                                text-transform: none;

                                &:hover {
                                    color: @white;
                                    background-color: @contrast1;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    @media @phone, @xsmall {
                        margin: 0 0 15px 0;

                        li {
                            a {
                                display: block;
                                line-height: 1.3;
                                text-transform: none;
                                padding: 5px 15px 5px 30px;
                                font-weight: 300 !important;
                            }
                        }
                    }

                }

                &:last-child {
                    ul {
                        left: auto;
                        right: 0;
                    }
                }

            }
        }
    }
}


.top-carrousel {
    position: relative;
    width: 100%;
    z-index: 0;

    @media @large {
		height: 590px;
    }
    @media @medium {
		height: 500px;
    }
    @media @small {
		height: 400px;
    }
    @media @phone, @xsmall {
		height: 300px;
    }

    .bx-wrapper,
    .bx-viewport {
        display: block;
        height: 100%;
    }

    ul {
        .no-bullets;
        height: 100%;
        li {

            height: 100%;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
			position: relative;
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: " ";
				.lh-background-image(linear-gradient(to bottom, rgba(0,0,0,0) 0%, fade(@black, 40%) 100%))
			}

            .container {
                position: relative;
                height: 100%;
                z-index: 2;
                padding: 0;
            }

            a {
                display: block;
                height: 100%;


                &:hover {
                }
            }

			
            span {
                display: block;

                
                position: absolute;
                left: 0;
                top: 30px;
                width: 100%;
				@media @phone, @xsmall {
                    top: 0;
				}
				@media @small {
                    top: 0;
				}

                h4 {
                    font-weight: normal;
                    color: @textcolor;
                    font-family: @subtitlefont;
					font-weight: @titleweight1;
                    line-height: 1;
                    text-align: center;
                    text-transform: uppercase;
                    @media @medium, @large {
                        .font-size(44);
                        line-height: 90px;
                    }
                    @media @small {
                        .font-size(28);
                    }

                    @media @phone, @xsmall {
                        .font-size(26);
                    }
                    &.linkedin {
                        &:before {
                            content: "";
                            display: inline-block;
                            background-image: url("../images/LinkedIn-logo.png");
                            background-repeat: no-repeat;
                            background-size: contain;
                            height: 90px;
                            width: 256px;
                            margin-right: 20px;
                            @media @phone, @xsmall {
                                margin-right: 8px;
                                height: 40px;
                                width: 113px;
                            }
                            vertical-align: bottom;
                        }
                    }
                }
                h6 {
                    display: none;
                }

            }
        }
    }
    .bx-controls {
        //position: relative;
        z-index: 20;

        @media @phone, @xsmall, @small {
            display: none;
        }

        .bx-controls-direction {
            a {
                background: fade(@white, 40%);
                text-indent: 0;
                width: 60px;
                height: 60px;
                margin-top: -25px;
                padding-top: 15px;
                text-align: center;
                text-decoration: none;
                &:before {
                    .icon;
                    .font-size(30);
                    color: @textcolor;

                }

                &.bx-prev {
                    left: 40px;

                    &:before {
                        content: '\f104';
                    }
                }
                &.bx-next {
                    right: 40px;

                    &:before {
                        content: '\f105';
                    }
                }

                &:hover {
                    background: fade(@color1, 70%);
                    &:before {
                        color: @white;
                    }

                }
                @media @small, @phone, @xsmall {
                    background: fade(@black, 30%);
                    &.bx-prev {
                        left: 0;
                    }
                    &.bx-next {
                        right: 0;
                    }

                }
            }

        }
    }
}
.header-image {
    //.container;
    background-position: top center;
    background-size: cover;

    position: relative;
    width: 100%;
    z-index: 0;


    @media @large {
        height: 450px;
        a > img {
            position: absolute;
            max-width: 300px;
            bottom: 0;
            right: 50px;
        }
    }

    @media @medium {
        height: 450px;
        a > img {
            position: absolute;
            max-width: 250px;
            bottom: 0;
            right: 0;
        }
    }
    @media @small {
        height: 300px;
      a > img {
        position: absolute;
        max-width: 160px;
        bottom: 0;
        right: 0;
      }

    }
    @media @phone, @xsmall {
        height: 120px;
      a > img {
        position: absolute;
        max-width: 100px;
        bottom: 0;
        right: 0;
      }

    }
    a {
        display: block;
        height: 100%;
        position: relative;
    }
}

.bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;

}

.pageheader {
    position: relative;
    z-index: 9;

	hgroup {
		position: relative;
		z-index: 2;

		h1 {
			&:extend(.zerniq_wysiwyg h1);
			text-align: center;
			margin: 0;
			@media @xsmall, @phone {
				.font-size(26);
			}
		}
		h4 {
			&:extend(.zerniq_wysiwyg h5);
			text-align: center;
		}
	}
}


.breadcrumbs {
    clear: both;
    position: relative;
    z-index: 3;
    background: @white;
    .font-size(16);
    color: #ababab;
    font-family: @contentfont;
    text-transform: lowercase;

    @media @small, @medium, @large {
        margin-top: 10px;

        .header-image + &,
        .top-carrousel + & {
            margin-top: 0;
        }
    }

    @media @phone, @xsmall {
        .font-size(14);
    }


    .container {
        padding-top: 7px;
        padding-bottom: 7px;
        //padding-left: 0;
        //padding-right: 0;
        background: transparent;
        margin-bottom: 15px;
    }
    ul, ol {
        .no-bullets;

        li {
            display: inline-block;

            a {
                color: #ababab;
                &:hover {
                    color: @color1;
                    text-decoration: none;
                }
            }

            &:after {
                content: '|';
                display: inline-block;
                padding: 0 2px 0 5px;
            }

            &:last-child {
                color: #ababab;
                a {
                    color: #ababab;
                }
                &:after {
                    content: '';
                }
            }
        }
    }

    .sticky-wrapper + & {
        padding-top: 15px;
    }
}

.sidemenu {
    @media @phone, @xsmall {
        display: none;
    }

    ul {
        .no-bullets;
        li {
            border-bottom: 1px solid fade(@color1, 60%);
            a {
                position: relative;
                display: block;
                .font-size(18);
                font-weight: 300 !important;
                line-height: 1.1;
                color: @black !important;
                padding: 15px 8px 8px 8px !important;

                &:hover {
                    text-decoration: none !important;
                    background-color: fade(@color1, 20%);
                }
            }

            &.active {
                border-bottom: 0;
                > a {
                    background-color: fade(@color1, 83%);
                    color: @white !important;
                }
            }

            ul {
                border-top: 1px solid fade(@contrast1, 50%);
                li {
                    padding-left: 20px;
                    border-bottom: 1px solid fade(@contrast1, 50%);

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .page-home & {
        ul {
            li {
                a {

                }
            }
        }
    }
}


.content {
    clear: both;
    position: relative;
    z-index: 2;
    min-height: 200px;


    > section {
        padding-top: 50px;
        padding-bottom: 50px;
		@media @phone, @xsmall {
			padding-top: 20px;
        	padding-bottom: 20px;
		}
        p:last-child {
            margin-bottom: 0;
        }

        /*&:first-child {
            @media @small, @medium, @large {
                padding-top: 50px;
            }

            @media @phone, @xsmall {
                padding-top: 30px;
            }
        }*/

        @media @phone, @xsmall {
            //margin: 0 15px;
        }

        .block {
            .clearfix;
            //min-height: 50px;
            &:extend(.zerniq_wysiwyg all);

        }
        &:not(.wide){
            > .block {
                .container;
                padding: 0;
            }
        }
        &.wide {
            > .block {
                .container-fluid;
                padding: 0;
            }
        }


        &.first-block {
            .col-left {
                .make-sm-column(4);
                .make-md-column(3);
            }
            .col-right {
                .make-sm-column(8);
                .make-md-column(9);
            }
        }

        &.bgcolor-1 {
            background-color: @color1;
            *, a, .block-title {
                color: @white;
            }
            h1,h2,h3,h4 {
                color: @white;
            }
            ul {
                li {
                    &:before {
                        color: @white;
                    }
                }
            }
			a.knop,
			button.knop {
				background-color: @white;
				border: 1px solid @white;
				color: @text-color;
				text-decoration: none;
				font-weight: @textweight;
				&:hover {
					color: @color1;
					border: 1px solid @color1;
				}
				&.uppercase {
					text-transform: uppercase;
				}
				&.big {
					.font-size(20);	
				}
			}
			
        }

        &.bgcolor-2 {
            background-color: #f1f1f2;
			a.knop,
			button.knop {
				background-color: @color2;
				border: 1px solid @color2;
				text-decoration: none;
				font-weight: @textweight;
				&:hover {
					color: @color1;
					border: 1px solid @color1;
				}
				&.uppercase {
					text-transform: uppercase;
				}
				&.big {
					.font-size(20);	
				}
			}
        }

        &.bgcolor-3 {
            background-color: @color2;
            *, a, .block-title {
                color: @white;
            }
            h4 {
                color: @white;
            }
            ul {
                li {
                    &:before {
                        color: @white;
                    }
                }
            }
        }



        // Homepage exceptions

        /*&:first-child {
            .page-home & {
                padding-top: 0;
                background: @grey;
                .block * {
                    color: @white !important;
                }
            }
        }*/

        &:not([class^='content-1']) .block-title {
            .make-sm-column(12);
        }
    }


    //.banners-120, .banners-121, .banners-122, .banners-123 {

    //}


    .image-block {
        min-height: 100px !important;
        background-position: center;
        background-size: cover;
    }
}

.addthis {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;

}

.fit-vids-style {
    max-height: 0;
}

.newslink,
.caseslink {
	.news-112 & {
		figure {
	        @media @phone, @xsmall  {
				display: none;
        	}

			//.make-sm-column(6);
			
		}
		span {
			//.make-xs-column(12);
			//.make-sm-column(6);
			//padding: 0 0 0 30px;
			@media @phone, @xsmall  {
            	padding: 0;
			}

		}
		.clearfix;
	}
	.news-110 &,
	.news-112 &,
	.cases-overview & {
		figure {
			position: relative;

		}
		span {
			margin-top: 5px;
		}
	}
	figure {
		height: 200px;
        background-position: center;
        background-size: cover;
		//.lh-box-shadow(0px 0px 15px 6px rgba(0, 0, 0, 0.1));
    }
    span {
        position: relative;
        display: block;
		
        h3 {
            color: @textcolor !important;
            
            margin: 0 0 4px 0 !important;
            text-transform: none !important;
        }
        time {
            .font-size(13) !important;
            color: #999999;
            margin-bottom: 3px !important;
        }
        p {
            //display: none;
        }
    }

    &:hover {
        text-decoration: none !important;
        h3 {
            color: @color1 !important;
        }
		figure {
			.lh-box-shadow(0px 0px 15px 6px rgba(0, 0, 0, 0.2));
			&:after {
				background-color: @color1;
				color: @white;
			}
		}

    }
}
/*.banner,
.newslink {
    @media @large {
        height: 520px;
    }
    @media @medium {
        height: 200px;

    }

    @media @small {
        height: 260px;
    }

    @media @phone, @xsmall {
        height: 220px;
        margin: 0 0 @grid-gutter-width 0;
    }
}*/
.news-single-item {
    &:extend(.content-10 all);
    .block {
        padding: 0 !important;
    }
    .fluid-width-video-wrapper {
        margin-bottom: 10px;
    }

    nav {
        .make-sm-column(3);

        @media @phone, @xsmall {
            display: none;
        }

        ul.linklist {
            li {
                background-color: transparent !important;
                a {
                    padding: 10px 0 !important;
                    text-align: left !important;
                    color: @textcolor;
                }
                &:hover {
                    a {
                        color: @color1 !important;
                        text-decoration: none;
                    }
                }
                &:before {
                    content: none;

                }
				border-bottom: solid 1px @contrast2;
				border-top: none  !important;
            }
        }
    }
    article {
        .make-sm-column(9);
		figure {
			text-align: center;
			img {
				//max-width: 50% !important;
			}
		}

        @media @phone, @xsmall {
            padding: 0 15px;
        }
    }
}
.deurmat {
    position: relative;
    z-index: 2;
    background: @grijs-donker;
    color: @white;

    .container {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    a {
        color: @white;
        &:hover {
            text-decoration: underline;
            color: @contrast1;
        }
    }

    .column {
        .make-sm-column(6);
        .make-md-column(3);

       @media @small {
            &:nth-child(3){
                clear: both;
            }
        }
		&.c1 {
			.make-md-column-pull(6);	
		}
		&.c2 {
			.make-md-column-pull(6);	
		}
		&.c3 {
			.make-md-column-push(6);	
		}
		&.c4 {
			.make-md-column-push(6);	
		}
        @media @phone, @xsmall {
            margin-bottom: 15px;
        }
		
		h3 {
			&:extend(.zerniq_wysiwyg h5);
			color: @white;
			margin-top: 0;
			text-transform: uppercase;
		}
    }

    p {
        margin: 0 0 20px 0;
    }

    p + ul {
        margin-top: -20px;
    }

    ul {
        .no-bullets;
        //margin: 0 0 20px 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 8px;
            /*&:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "-\0020";
            }*/
        }
    }
	
	h3 {
		line-height: 1.5;
	}
	
		button.knop {
			&:extend(.zerniq_wysiwyg button.knop);	
			background-color: @oranje;
		}
		input {
			background-color: fade(@white,80%);
			border: 0;
			box-shadow: none;
			outline: 0;
			height: 40px;
			padding: 9px 12px;
			color: @oranje;
		}


}
.footer {
    position: relative;
    z-index: 2;
    padding: 8px 0;
    background: none;

    .block {
       .clearfix;
        &:extend(.zerniq_wysiwyg all);
       .container;
        padding: 0;
    }

    .font-size(14);

    .copyright {
        .make-sm-column(6);
        color: @color1;
        text-align: right;
    }
    .links {
        .make-sm-column(6);
        color: @color1;

        a + a {
            margin-left: 20px;
        }
        ul {
            .no-bullets;
            li {
                display: inline-block;
                margin-left: 12px;
            }
        }
    }

}


section.content-c_11 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	&.bgcolor-1 {
		.lh-box-shadow( inset 0px 20000px 0px 0px fade(@color1, 70%));	
	}
}

section.cases-overview {
    .block {
        padding: 0 15px;
    }
	.filters {
		margin-bottom: 30px;
		text-align: center;
	}
	a.filter {
		display: inline-block;
		padding: 5px 10px;
		margin: 0 10px 15px;
		border: 1px solid fade(@grey, 70%);
		color: fade(@grey, 70%);
		.lh-border-radius(3px);
		text-decoration: none;
		background: @white;

		&:hover {
			background: @color1;
			border-color: @color1;
			color: @white;
			text-decoration: none;
		}

		&.active {
			//border-color: fade(@grey, 70%) !important;
			border-color: #999999;
			background: #999999;
			color: @white;
		}
	}
	.mix {
		display: none;
	}
    .cases-item {
        .make-xs-column(12);
        .make-sm-column(4);
		.make-md-column(4);

        margin-bottom: 30px;
		@media @small {
			&:nth-child(3n+1) {
				clear: both;
			}
		}
		@media @medium, @large {
			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}
}

section.whitepaper {
	.col1 {
		.make-sm-column(6);
		.make-md-column(6);
		.make-md-column-offset(2);
		.form-whitepaper {
			.col-sm-6 {
				padding-right: 5px;
			}
			.col-sm-6 + .col-sm-6 {
				padding-left: 5px;
				padding-right: 15px;
			}
		}
	}
	.col2 {
		.make-sm-column(3);
		position: relative;
		img {
			position: absolute;
			top: -60px;
			.lh-box-shadow(5px 5px 5px 0px rgba(0,0,0,0.4));
			border: 1px solid @color1;
		}
	}
	//margin-bottom: 60px;
}
