
.kop {
    color: @titlecolor;
	font-weight: @titleweight1;
    font-family: @titlefont;
}

.zerniq_wysiwyg {
    font-family: @contentfont;
    line-height: 1.6;
    color: @textcolor;

    h1, h2, h3, h4 {
        .kop;
    }
    h1 {
        .font-size(48);
		line-height: 1.2;
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(30);
        }
        & + h1,
        & + h2 {
            margin-top: -0.5em;
        }
    }

    h2 {
		color: @titlecolor;
        .font-size(36);
        margin: 0 0 0.6em 0;
        @media @phone, @xsmall {
            .font-size(26);
        }
        & + h1,
        & + h2 {
            margin-top: -0.5em;
        }
    }

    h3, h4 {
        .font-size(24);
        margin: 0 0 0.5em 0;

        @media @phone, @xsmall {
            .font-size(20);
        }
    }
    h3 {
		color: @color1;
    }
    h4 {
		color: @color1;
		font-weight: @titleweight2;
    }

    h5, h6 {
        .font-size(18);
		font-weight: @titleweight1;
		font-family: @contentfont;
        &.center-heading {
            margin-left: auto;
            margin-right: auto;
        }
    }
	
	h5 {
		color: @color1;
	}
	
	h6 {
		color: @textcolor;

	}

    .block-title {
        .kop;
		color: @titlecolor;
        .font-size(36);
        margin: 0 0 0.6em 0;
        text-align: center;

        @media @phone, @xsmall {
            .font-size(24);
        }
    }

    a {
        color: @contrast1;

        &:hover {
            color: @color1;
            text-decoration: underline;
        }
    }

    img {
        max-width: 100%;
        height: auto; // !important;
    }

    p {
        margin: 0 0 1.3em 0;
    }

    p + ul,
    p + ol {
        margin-top: -1.5em;
    }

    time {
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
        .font-size(15);
    }
    /*
    ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
        counter-reset: item;

        li {
            position: relative;
            margin: 0;
            padding-left: 50px;
            color: @color1;
            min-height: 50px;
            counter-increment: item;
            .font-size(20);
            padding-top: 2px;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: counters(item,".") " ";
                width: 40px;
                height: 40px;
                .lh-border-radius(50%);
                background: @color1;
                color: @white;
                .font-size(18);
                font-weight: 400;
                text-align: center;
                padding-top: 3px;
            }

        }
    }
    */
    ol {
        margin: 0 0 1.3em 0;
    }
    ul {
        margin: 0 0 1.3em 0;
        @media @phone {
            padding-left: 15px;

            ul {
                padding-left: 15px !important;
            }
        }

        li {
            position: relative;
        }
        &.filelist {
            list-style-type: none;

            padding-left: 5px;
            li {
                a {
                    padding: 5px 0;
                    display: inline-block;
                }
                &:before {
                    content: none;
                }
            }
        }
        &.linklist {
            
            .lh-border-radius(0);
			.no-bullets;
            padding: 0;
			margin: 0 auto;
            width: 300px;
            max-width: 100%;
            li {
                background-color: @color2;
                a {
                    display: block;
                    padding: 10px;
                    text-align: center;
                    color: @textcolor;
                }
                &:hover {
                    background-color: @color1;
                    a {
                        color: @white;
                        text-decoration: none;
                    }
                }
                &:before {
                    content: none;

                }
				& + li {
					border-top: solid 1px @contrast1;
				}
            }
        }
    }



    strong {
		font-weight: normal;
		color: @grijs-donker;
    }

    .intro {
        .font-size(20);
        line-height: 1.67;
		color: @grijs-donker;
    }

    .source {
        font-style: italic;
        font-size: 80%;
    }

    
    blockquote {
        display: block;
        text-align: center;
        color: @color1;
        font-weight: 600;
        margin: 0;
        padding: 25px 25px;
        border: none;
		border-left: 5px solid @color1;
        .font-size(20);
        line-height: 1.25;
		margin-left: 50px;
		margin-bottom: 20px;
        @media @phone, @xsmall {
            .font-size(18);
        }

        &:before {
            content: '"';
        }
        &:after {
            content: '"';
        }
    }
    


    a.knop,
	button.knop {
        display: inline-block;
        padding: 4px 28px;
        color: @white;
		background-color: @color1;
		border: 1px solid @color1;
        text-decoration: none;
        font-weight: @textweight;
        &:hover {
        	background: @white;
			color: @color1;
        }
		&.uppercase {
			text-transform: uppercase;
		}
		&.big {
			.font-size(24);
		}
    }

    .quote {
        font-family: 'Raleway';
		font-weight: 300;
        padding: 10px 0;
        .font-size(24);
        &.quote-big {
            .font-size(36);
        }
		@media @phone, @xsmall {
			.font-size(20);
		}
    }

    p.text-blauw,
    .text-blauw {
        color: @contrast1;
    }

    p.text-oranje,
    .text-oranje {
        color: @color1;
    }

    p.text-groot,
    .text-groot {
        .font-size(20);
    }
}
