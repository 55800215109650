section.news-110 {
    .block {
        padding: 0 15px;
    }

    .news-item {
        .make-xs-column(12);
        .make-sm-column(4);
		.make-md-column(4);

        margin-bottom: 30px;
		@media @small {
			&:nth-child(3n+1) {
				clear: both;
			}
		}
		@media @medium, @large {
			&:nth-child(3n+1) {
				clear: both;
			}
		}

        /*.newslink {
            height: auto;
            background: none;
            position: relative;
            &:hover {
                h3, p {
                    color: @grey !important;
                }
            }
            span {
                height: auto;
                margin-bottom: 30px;
            }
            &:after {
                border-bottom: 3px solid @color1;
                content: " ";
                width: 40%;
                margin-bottom: 20px;
                margin-top: 10px;
                left: 50%;
                position: absolute;
                bottom: 0;
                margin-left: -20%;
            }
        }*/
    }
}
