section.news-112 {
    position: relative;
    .news-item {
        .make-xs-column(12);
        .make-sm-column(4);
        .newslink {
			
        }
        @media @phone, @xsmall  {
            margin-bottom: @grid-gutter-width;
        }

    }

}
