section.banners-127 {

    .banner-container {
        //.make-row();
        //
        //.make-sm-column-offset(1);
		.make-xs-column(12);
		.col-centered;
		padding: 0;
		@media @phone,@xsmall {
			padding: 0 15px;
		}
        div {
            .banner-subnav {
				.clearfix;
				figure {
					.make-xs-column(6);
					padding: 0;
					background-position: center center;
					background-size: contain;
					background-repeat: no-repeat;
					background-color: transparent;
					@media @large {
						height: 270px;
					}
					@media @medium, @small {
						height: 200px;
					}
					@media @phone,@xsmall {
						height: 120px;
					}
				}
				span {
					.make-xs-column(6);
					padding: 30px 0 0 30px;
					h3 {
						font-weight: @titleweight2;	
					}
					h5 {
						color: @textcolor;
						
					}
					a.knop {
						background-color: @color1;
						border-color: @color1;
						text-transform: uppercase;
						&:after {
							.icon;
							padding-left: 8px;
							content: "\f105";
						}
						&:hover {
							background-color: @white;
						}
					}
				}
					
            }
        }


    }
    .col {
        .make-sm-column(6);
		padding: 0;
		padding-bottom: 30px;
		&:last-child {
			padding-bottom: 0;
		}
    }
}
