// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black: #000;
@white: #FFF;
@grey: #888;

@oranje: #ee8214; //#ea6b13;
@blauw: #0099de; //008cdc;
@steunkleur: #3a4341;

@orange: #ffae3e;

@grijs: #73787b;
@grijs-licht: #8f9395;
@grijs-donker: #3a4341;

@banner-text: @black;

@color1: @oranje;
@color2: @grijs;
@contrast1: @blauw;
@contrast2: @grijs-donker;
@textcolor: @grijs-donker;
@titlecolor: @oranje;

@bordercolor: @grijs-donker;

// mix with white: tint()
// mix with black: shade()


// Typography
@contentfont: 'Open Sans', Arial, Helvetica, sans-serif;
@titlefont: 'Raleway';
@subtitlefont: @titlefont;

@textweight: 400;
@titleweight1: 300;
@titleweight2: 400;

// Templates
@leegheight: 100px; //400px;

// Breakpoints
/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";

@mobile: @phone, @xsmall;
@nonmobile: @small, @medium, @large;

