section.banners-121 {
    /*.col1 {
        .make-sm-column(4);
        .make-sm-column-offset(2);
    }
    .col2 {
        .make-sm-column(4);
    }*/

    // Custom
    .col1,
    .col2 {
        .make-sm-column(6);
        @media @phone, @xsmall {
            margin-bottom: @grid-gutter-width;
        }
    }

}
