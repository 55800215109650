section.banners-122 {
    .col1 {
        .make-sm-column(4);
        @media @phone, @xsmall  {
            margin-bottom: @grid-gutter-width;
        }
    }
    .col2 {
        .make-sm-column(4);
        @media @phone, @xsmall  {
            margin-bottom: @grid-gutter-width;
        }
    }
    .col3 {
        .make-sm-column(4);
    }

}
